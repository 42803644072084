/* Custom CSS */

#stageContainer {
    width: 100%;
}

.OSoverallStats {
    font-weight: 600;
    font-size: 1.3em;
}
.OSoverallStatsSmall {
    font-weight: 100;
    font-size: 0.4em;
    line-height: 0.5;
}
.OSoverallStatsLegend {
    font-weight: 200;
    font-size: 0.8em;
}

/* Background Generator CSS */

.BGAssetLegend{
    font-size: 0.6em;
}

/* For the accordion that is part of both left/side panels */
.accordion-button {
    /* background-color: rgba(0,0,0,.03); */
    display: flex;
    flex-grow: 1 !important;
    justify-content: flex-start;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
}

/* For the carousel that display assets on the left hand side  */
.carousel-caption-custom {
    font-size: 0.8em;
}


/* Explorer CSS */

/* .filterBar {
    max-height: 90vh;
    overflow-y: auto;
} */

/* The pill that show the filter items selected  */
.filterBadge {
    color: white;
}
.filterBadge a {
    color:white;
}

/* body, html {
    margin: 0;
    height:100%;
  } */
/* body{
    overflow: hidden;
}

.containerx-fluid, .explorer-parent{
    height: 100%;
    background-color: yellow;
}
  
#right {
    background-color: #4FC1E9;
}
#left {
    background-color: #FC6E51;
}
  
#left, #right{
    position: relative;
    float: left;
    text-align: center;
    height:100%;
    overflow-y: auto; 
} */